<template>
  <div class="home">
    <div class="container">
      <div id="home" class="header">
        <img class="logo" src="../assets/img/home/happy-logo.png" alt="logo" />
        <div class="header-tips">您好，欢迎访问开心联考</div>
        <div class="user-login-btn" @click="toLogin">登录</div>
      </div>
      <div class="top-nav">
        <img class="logo" src="../assets/img/home/happy-logo.png" alt="logo" />
        <div class="top-nav-btns">
          <div
            class="btns-item"
            :class="{ 'btns-item-selected': index === 0 }"
            v-for="(item, index) in topNavBtns"
            :key="index"
            @click="clickNavBtn(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="tip1">如何一次性通过管理类联考考试？</div>
        <div class="tip2">{{ year }}·新征程</div>
        <img class="line" src="../assets/img/home/line.png" alt="" />
        <div class="tip3">开心联考学习班 不一样的联考辅导机构</div>
        <div class="apply-btn" @click="openMiniP">打开小程序</div>
        <div class="audio-div">
          <!-- <div class="audio-title">全年课程导学</div>
          <audio
            src="https://kxlk-base.oss-cn-beijing.aliyuncs.com/audio/C73A0C2C2C48405EA347092C2D674C76.mp3"
            controls="controls"
          ></audio> -->
          <div class="audio-title">百天冲刺计划</div>
          <audio
            src="https://kxlk-base.oss-cn-beijing.aliyuncs.com/audio/824966C190E9486F907513C3ABA5C73E.mp3"
            controls="controls"
          ></audio>
        </div>
      </div>
      <div class="how">
        <div class="title">如何一次性通过MBA/MEM/MPA/MPACC考试？</div>
        <div class="title-e">
          How to pass the MBA/MEM/MPA/MPACC exam at one time?
        </div>
        <div class="how-items">
          <div class="item item-1">
            <div class="mengceng">
              <div class="title">想要一次性通过联考，你需要来点不一样的！</div>
              <div class="content">
                联考考试全国通过率只有30%-35%，想要通过考试，你要独辟蹊径更加有效的复习，这样，你才不会沦为每年陪考的65%-70%。
              </div>
            </div>
          </div>
          <div class="item item-2">
            <div class="mengceng">
              <div class="title">建立你的竞争优势！</div>
              <div class="content">
                针对每个考生的基础制定相应的学习计划，阶段性反馈学习情况，保证每个学员在短时间内掌握联考核心内容，把握考试技巧。
              </div>
            </div>
          </div>
          <div class="item item-3" style="margin-right: 0">
            <div class="mengceng">
              <div class="title">助你一次性通过考试！</div>
              <div class="content">
                你的时间是有限的，你的精力是有限的，不浪费每一分钟。开心联考学习班精准针对考试要点重点，助你一次性通过考试。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="analysis" class="step-one">
        <div class="title">一、联考考生缺什么？</div>
        <div class="title-e">
          What are the candidates most lacking in the joint examination?
        </div>
        <div class="one-content">
          <div class="one-item">
            <div class="tip1">time</div>
            <div class="tip2">时间</div>
            <div class="tip3">在职、工作、家庭、小孩、陪伴</div>
            <div class="tip4">
              如何在有限的时间里进行有效的复习是我们通过考试的关键
            </div>
          </div>
          <div class="one-item">
            <div class="tip1">method</div>
            <div class="tip2">方法</div>
            <div class="tip3">埋头、苦干、辛苦、无用功、迷惘</div>
            <div class="tip4">好的方法，事半功倍<br />方法不对，努力白费</div>
          </div>
          <div class="one-item">
            <div class="tip1">information</div>
            <div class="tip2">好的资料</div>
            <div class="tip3">如山书籍，海量视频，众多的书籍，海量的视频</div>
            <div class="tip4">
              看不完也看不透，这其实是阻碍<br />是我们通过考试的障碍
            </div>
          </div>
          <div class="one-item">
            <div class="tip1">Persistence</div>
            <div class="tip2">坚持的毅力</div>
            <div class="tip3">事情干扰、诱惑、惰性、没计划、没干劲</div>
            <div class="tip4">联考的路很长<br />我们缺少坚持的毅力</div>
          </div>
        </div>
      </div>
      <div id="features" class="step-two">
        <div class="title">二、开心学习班特色是什么？</div>
        <div class="title-e">
          What are the characteristics of happy learning class?
        </div>
        <div class="two-content">
          <div class="two-item" style="margin-top: -30px">
            <div class="icon">
              <img src="../assets/img/home/icon-img1.png" />
            </div>
            <div class="tip1">本质规律安排复习</div>
            <div class="tip2">任何事情都有规律<br />联考考试也从不例外！</div>
          </div>
          <div class="two-item" style="margin-top: 100px">
            <div class="icon">
              <img src="../assets/img/home/icon-img2.png" />
            </div>
            <div class="tip1">注重高效复习方法策略</div>
            <div class="tip2">
              全面滚动螺推进复习方法！<br />“好”方法当然好效果！
            </div>
          </div>
          <div class="two-item" style="margin-top: -30px">
            <div class="icon">
              <img src="../assets/img/home/icon-img3.png" />
            </div>
            <div class="tip1">完善的复习资料</div>
            <div class="tip2">
              好的资料应该紧跟考试大纲，全面而又<br />重点突出，便于理解而又精准精炼！
            </div>
          </div>
          <div class="two-item" style="margin-top: 100px">
            <div class="icon-y">
              <img src="../assets/img/home/icon-img4.png" />
            </div>
            <div class="tip1">全程的复习规划</div>
            <div class="tip2">有把握的复习<br />应该从一开始就规划全局！</div>
          </div>
          <div class="two-item" style="margin-top: -30px">
            <div class="icon">
              <img src="../assets/img/home/icon-img5.png" />
            </div>
            <div class="tip1">可执行的具体复习计划</div>
            <div class="tip2">绝妙的复习计划<br />就是不一样！</div>
          </div>
          <div class="two-item">
            <div class="icon">
              <img src="../assets/img/home/icon-img6.png" />
            </div>
            <div class="tip1">全程解答疑惑</div>
            <div class="tip2">
              虽然正确有效的复习，疑难会大大减少，<br />但可靠的答疑服务必不可少！
            </div>
          </div>
        </div>
      </div>
      <div id="suit" class="step-three">
        <div class="title">三、开心学习班辅导适合哪些人参加？</div>
        <div class="title-e">
          Who is the most suitable person to participate in the happy learning
          class?
        </div>
        <div class="three-content">
          <div class="three-item">
            <div class="tip1">复习思路不清晰</div>
            <div class="line"></div>
            <div class="tip2">复习方法不正确、久考不过的考生</div>
          </div>
          <div class="three-item">
            <div class="tip1">时间精力管理</div>
            <div class="line"></div>
            <div class="tip2">
              在职考试、复习时间不多<br />希望节省复习时间和精力的考生
            </div>
          </div>
          <div class="three-item">
            <div class="tip1">陪伴督促</div>
            <div class="line"></div>
            <div class="tip2">惰性较大，需要带动、引导、监督复习的考生</div>
          </div>
          <div class="three-item">
            <div class="tip1">补强纠偏</div>
            <div class="line"></div>
            <div class="tip2">复习过程中感到迷惘、信心不足的考生</div>
          </div>
          <div class="three-item">
            <div class="tip1">及时解决</div>
            <div class="line"></div>
            <div class="tip2">零基础或者毕业多年基础不牢的考生</div>
          </div>
          <div class="three-item">
            <div class="tip1">随时答疑</div>
            <div class="line"></div>
            <div class="tip2">复习的过程中疑问相对较多的学生</div>
          </div>
        </div>
      </div>
      <div id="contactus" class="contactus">
        <div class="name">联系人：陈老师</div>
        <div class="phone">电话：18817321388</div>
        <div class="qq">QQ：2276472618</div>
        <img
          class="wechat-ercode"
          src="../assets/img/home/lianxi-ercode.jpeg"
          alt="微信二维码"
        />
      </div>
      <div id="payment" class="pay-title">支付方式</div>
      <div class="payment">
        <img
          class="pay-ercode"
          src="../assets/img/home/aliPay.jpeg"
          alt="支付宝"
        />
        <img
          class="pay-ercode"
          src="../assets/img/home/wechatPay.png"
          alt="微信"
        />
      </div>
      <div class="footer">
        <span>版权所有：北京慧锐博学教育科技有限公司</span>
        <span style="margin: 20px">京ICP备20020529号-1</span>
        <span>京公网安备 11010802032040号</span>
      </div>
    </div>
    <el-dialog
      title="使用微信扫描下方二维码打开小程序"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="dialog-content">
        <img
          class="ercode-img"
          src="../assets/img/home/ercode.jpg"
          alt="小程序二维码"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      topNavBtns: [
        {
          id: "home",
          name: "首页",
        },
        {
          id: "course",
          name: "课程",
        },
        {
          id: "contactus",
          name: "咨询联系",
        },
        {
          id: "payment",
          name: "支付方式",
        },
      ],
      year: new Date().getFullYear() + 1,
      centerDialogVisible: false,
    };
  },
  methods: {
    //去登录
    toLogin() {
      this.$router.push("/login");
    },
    //点击导航栏按钮
    clickNavBtn(id) {
      console.log(id);
      if (id === "course") {
        console.log("课程");
        this.$router.push("/courseList");
      } else {
        document.getElementById(id).scrollIntoView();
      }
    },
    //打开小程序
    openMiniP() {
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .container {
    width: 100%;
    .header {
      height: 57px;
      background-color: #212121;
      display: flex;
      justify-content: space-between;
      padding: 0 200px;
      box-sizing: border-box;
      .logo {
        display: none;
      }
      .header-tips {
        font-size: 17px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #d8d8d8;
        line-height: 57px;
      }
      .user-login-btn {
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #fff;
        line-height: 57px;
        cursor: pointer;
      }
    }
    .top-nav {
      border-bottom: 1px solid #eeeeee;
      background-color: #ffffff;
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      padding: 0 200px 0 175px;
      box-sizing: border-box;
      .logo {
        width: 234px;
        height: 104px;
        flex: 0 0 234px;
        margin-right: 200px;
      }
      .top-nav-btns {
        flex: 1;
        display: flex;
        align-items: center;
        .btns-item {
          font-size: 17px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
          cursor: pointer;
          margin-right: 50px;
        }
        .btns-item-selected {
          color: #49dcbf;
        }
      }
    }
    .banner {
      width: 100%;
      height: 600px;
      background: url("../assets/img/home/banner-bg.png") no-repeat center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .tip1 {
        font-size: 72px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 60px;
      }
      .tip2 {
        font-size: 36px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        margin-top: 65px;
      }
      .tip3 {
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.61);
      }
      .line {
        width: 708px;
        height: 2px;
      }
      .apply-btn {
        width: 158px;
        height: 70px;
        background-color: #49dcbf;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 70px;
        text-align: center;
        border-radius: 6px;
        margin-top: 65px;
        cursor: pointer;
      }
      .audio-div {
        .audio-title {
          color: #fff;
          font-size: 20px;
          text-align: center;
          padding: 30px 0 10px;
        }
      }
    }
    .how {
      width: 100%;
      height: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 48px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
      }
      .title-e {
        margin-top: 18px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #999999;
      }
      .how-items {
        display: flex;
        justify-content: space-between;
        margin-top: 65px;
        .item {
          width: 369px;
          height: 456px;
          margin-right: 15px;
          background-color: #d8d8d8;
          .mengceng {
            width: 100%;
            height: 100%;
            background-color: rgba(46, 63, 81, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title {
              height: 60px;
              width: 300px;
              font-size: 15px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #49dcbf;
              line-height: 25px;
              border-top: 2px solid #49dcbf;
              border-bottom: 2px solid #49dcbf;
              text-align: center;
              line-height: 60px;
            }
            .content {
              width: 260px;
              font-size: 15px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #ffffff;
              line-height: 41px;
              margin-top: 50px;
            }
          }
        }
        .item-1 {
          background: url("../assets/img/home/how-item-1.png") no-repeat center;
        }
        .item-2 {
          background: url("../assets/img/home/how-item-2.png") no-repeat center;
        }
        .item-3 {
          background: url("../assets/img/home/how-item-3.png") no-repeat center;
        }
      }
    }
    .step-one {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 48px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
      }
      .title-e {
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #999999;
      }
      .one-content {
        width: 100%;
        height: 500px;
        background: url("../assets/img/home/one-content-bg.png") no-repeat
          center;
        margin-top: 40px;
        display: flex;
        justify-content: space-around;
        .one-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .tip1 {
            font-size: 60px;
            font-family: RTWS YueGothic G0v1;
            font-weight: 300;
            color: #ffffff;
            line-height: 90px;
          }
          .tip2 {
            font-size: 40px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            line-height: 54px;
            margin-top: 20px;
          }
          .tip3 {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            line-height: 32px;
            margin-top: 20px;
          }
          .tip4 {
            width: 200px;
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            line-height: 27px;
            margin-top: 20px;
            text-align: center;
          }
        }
      }
    }
    .step-two {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 80px;
      padding-bottom: 50px;
      .title {
        font-size: 40px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #5f616d;
        line-height: 30px;
      }
      .title-e {
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #999999;
        line-height: 30px;
        margin-top: 15px;
      }
      .two-content {
        width: 100%;
        height: 300px;
        background: url("../assets/img/home/line-bolang.png") no-repeat center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 100px;
        box-sizing: border-box;
        .two-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 60px;
            height: 60px;
            background: url("../assets/img/home/yuan-bg-blue.png") no-repeat
              center;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
          .icon-y {
            width: 60px;
            height: 60px;
            background: url("../assets/img/home/yuan-bg-yellow.png") no-repeat
              center;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
          .tip1 {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #258ffc;
            line-height: 30px;
          }
          .tip2 {
            font-size: 12px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #5f616d;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
    .step-three {
      width: 100%;
      background-color: #f2f2f2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0;
      .title {
        font-size: 32px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #222222;
      }
      .title-e {
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #999999;
      }
      .three-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 50px;
        width: 975px;
        .three-item {
          width: 315px;
          height: 225px;
          background-color: #ffffff;
          margin-bottom: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #cd9752;
            .tip1,
            .tip2 {
              color: #ffffff;
            }
            .line {
              background: #ffffff;
            }
          }
          .tip1 {
            font-size: 20px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #333333;
          }
          .line {
            width: 40px;
            height: 2px;
            background: #e7aa5b;
            margin: 30px 0;
          }
          .tip2 {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #333333;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
    .contactus {
      background-color: #ffffff;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      justify-content: center;
      align-items: center;
      .name,
      .phone,
      .qq {
        font-size: 18px;
        color: #5f616d;
        line-height: 30px;
      }
      .wechat-ercode {
        width: 200px;
        height: 200px;
      }
    }
    .pay-title {
      font-size: 20px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: normal;
      font-family: Adobe Heiti Std;
    }
    .payment {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .pay-ercode {
        width: 200px;
        margin-bottom: 20px;
      }
    }
    .footer {
      width: 100%;
      height: 100px;
      background-color: #212121;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .dialog-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ercode-img {
      width: 220px;
      height: 220px;
    }
  }
}
@media screen and (max-width: 900px) {
  .home {
    .container {
      width: 100%;
      .header {
        height: 30px;
        background-color: #fff;
        padding: 0 10px 0 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .logo {
          display: block;
          width: 65px;
          height: 30px;
          flex: 0 0 65px;
          margin-right: 10px;
        }
        .header-tips {
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
          line-height: 30px;
        }
        .user-login-btn {
          display: none;
        }
      }
      .top-nav {
        border-bottom: none;
        background-color: #212121;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
        .logo {
          display: none;
        }
        .top-nav-btns {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btns-item {
            font-size: 12px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            cursor: pointer;
            margin-right: 0;
          }
          .btns-item-selected {
            color: #49dcbf;
          }
        }
      }
      .banner {
        width: 100%;
        height: 300px;
        background: url("../assets/img/home/banner-bg.png") no-repeat center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tip1 {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 60px;
        }
        .tip2 {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
          margin-top: 0px;
        }
        .tip3 {
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.61);
        }
        .line {
          width: 350px;
          height: 1px;
        }
        .apply-btn {
          width: 80px;
          height: 35px;
          background-color: #49dcbf;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 35px;
          text-align: center;
          border-radius: 4px;
          margin-top: 20px;
          cursor: pointer;
        }
      }
      .how {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
          margin-top: 20px;
        }
        .title-e {
          margin-top: 0px;
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #999999;
          margin-bottom: 20px;
        }
        .how-items {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 0;
          width: 100%;
          .item {
            width: 100%;
            height: 456px;
            margin-right: 0;
            margin-top: 0px;
            background-color: #d8d8d8;
            .mengceng {
              width: 100%;
              height: 100%;
              background-color: rgba(46, 63, 81, 0.9);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .title {
                height: 60px;
                width: 300px;
                font-size: 15px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #49dcbf;
                line-height: 25px;
                border-top: 2px solid #49dcbf;
                border-bottom: 2px solid #49dcbf;
                text-align: center;
                line-height: 60px;
              }
              .content {
                width: 260px;
                font-size: 15px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #ffffff;
                line-height: 41px;
                margin-top: 50px;
              }
            }
          }
          .item-1 {
            background: url("../assets/img/home/how-item-1.png") no-repeat
              center;
          }
          .item-2 {
            background: url("../assets/img/home/how-item-2.png") no-repeat
              center;
          }
          .item-3 {
            background: url("../assets/img/home/how-item-3.png") no-repeat
              center;
          }
        }
      }
      .step-one {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
          margin-top: 20px;
        }
        .title-e {
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #999999;
          margin-bottom: 20px;
        }
        .one-content {
          width: 100%;
          height: auto;
          background: url("../assets/img/home/one-content-bg.png") center;
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .one-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px 0;
            .tip1 {
              font-size: 40px;
              font-family: RTWS YueGothic G0v1;
              font-weight: 300;
              color: #ffffff;
              line-height: 40px;
            }
            .tip2 {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 40px;
              margin-top: 0px;
            }
            .tip3 {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 20px;
              margin-top: 10px;
            }
            .tip4 {
              width: 200px;
              font-size: 12px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #ffffff;
              line-height: 20px;
              margin-top: 10px;
              text-align: center;
            }
          }
        }
      }
      .step-two {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px;
        .title {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #222222;
          line-height: 15px;
          margin-top: 30px;
        }
        .title-e {
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #999999;
          line-height: 17px;
          margin-top: 0px;
        }
        .two-content {
          width: 95%;
          height: auto;
          background: url("../assets/img/home/line-bolang.png") no-repeat center;
          background-size: contain;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0;
          padding-bottom: 30px;
          box-sizing: border-box;
          .two-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon {
              width: 20px;
              height: 20px;
              background: url("../assets/img/home/yuan-bg-blue.png") no-repeat
                center;
              background-size: contain;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 10px;
                height: 10px;
                display: block;
              }
            }
            .icon-y {
              width: 20px;
              height: 20px;
              background: url("../assets/img/home/yuan-bg-yellow.png") no-repeat
                center;
              background-size: contain;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 10px;
                height: 10px;
                display: block;
              }
            }
            .tip1 {
              font-size: 10px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #258ffc;
              line-height: 20px;
            }
            .tip2 {
              display: none;
            }
          }
        }
      }
      .step-three {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        .title {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #222222;
        }
        .title-e {
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #999999;
          text-align: center;
        }
        .three-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 0px;
          width: 100%;
          .three-item {
            width: 315px;
            height: 150px;
            background-color: #ffffff;
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:hover {
              background-color: #cd9752;
              .tip1,
              .tip2 {
                color: #ffffff;
              }
              .line {
                background: #ffffff;
              }
            }
            .tip1 {
              font-size: 20px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #333333;
            }
            .line {
              width: 40px;
              height: 2px;
              background: #e7aa5b;
              margin: 15px 0;
            }
            .tip2 {
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #333333;
              line-height: 24px;
              text-align: center;
            }
          }
        }
      }
      .contactus {
        background-color: #ffffff;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        justify-content: center;
        align-items: center;
        .name,
        .phone,
        .qq {
          font-size: 15px;
          color: #5f616d;
          line-height: 30px;
        }
        .wechat-ercode {
          width: 200px;
          height: 200px;
        }
      }
      .footer {
        width: 100%;
        height: 100px;
        background-color: #212121;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
      }
    }
    .dialog-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ercode-img {
        width: 220px;
        height: 220px;
      }
    }
  }
  /deep/.el-dialog {
    width: 95% !important;
  }
}
</style>